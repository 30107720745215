import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { filterPublished } from 'utils/project-utils';

export default function useFeaturedHomeProjects() {
  const rawData = useStaticQuery(graphql`
    query ProjectFeaturedHomeQuery {
      projects: allMdx(
        filter: {
          fields: { slug: { regex: "/projects/" } }
          frontmatter: { isFeaturedHome: { eq: true } }
        }
        sort: { fields: [frontmatter___homeOrder], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              createdAt
              url
              client
              type
              isFeaturedHome
              description
              homeOrder
              published
              imageTeaser {
                childImageSharp {
                  gatsbyImageData(width: 550)
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = useMemo(() => {
    return rawData.projects.edges
      .map(({ node }) => ({
        id: node.fields.slug,
        ...node.fields,
        ...node.frontmatter,
      }))
      .filter(filterPublished)
      .slice(0, 6);
  }, [rawData]);

  return data;
}
