import React from 'react';
import { Flex, Box } from 'components/ReflexBox';

import ClientCard from 'components/ClientCard';

const clients = [
  ['zeit_online', '80%', '60%'],
  ['taz', '80%', '45%'],
  ['financial_times', '80%', '60%'],
  ['funke', '80%', '50%'],
  ['okf', '80%', '60%'],
  ['correctiv', '80%', '50%'],
  ['oecd', '80%', '60%'],
  ['fixmycity', '80%', '70%'],
  ['srf', '80%', '50%'],
  ['db', '80%', '50%'],
  ['nzz', '80%', '50%'],
  ['brand_eins', '80%', '30%'],
];

const ClientGrid = (props) => {
  const gridSize = [1 / 2, 1 / 2, 1 / 4];
  const gridPadding = 1;

  return (
    <Flex
      backgroundColor="dark.background"
      flexWrap="wrap"
      marginX={-gridPadding}
      {...props}
    >
      {clients.map((client) => (
        <Box key={client} width={gridSize} padding={gridPadding}>
          <ClientCard width={client[1]} height={client[2]} client={client[0]} />
        </Box>
      ))}
    </Flex>
  );
};

export default ClientGrid;
