import React from 'react';
import { Flex, Box } from 'components/ReflexBox';
import styled from '@emotion/styled';

import { H4, TextLight } from 'components/Typo';
import Icon from 'components/Icon';

const GridItem = ({ title, label, iconType }) => {
  return (
    <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 4]} px={[0, 0, 2, 2, 2]} py={2}>
      <Box bg="dark.cardBackground" px={3} pt={2} pb={3} height="100%">
        <Flex alignItems="top" justifyContent="space-between">
          <H4>{title}</H4>
          <Icon size={22} pt={2} name={iconType} />
        </Flex>
        <TextLight color="textLight">{label}</TextLight>
      </Box>
    </Box>
  );
};

const StyledFlex = styled(Flex)`
  max-width: 1400px;
`;

const ServiceGrid = (props) => {
  return (
    <StyledFlex mx={[0, 0, -2, -2, 'auto']} flexWrap="wrap" {...props}>
      <GridItem
        title="Data Visualizations"
        label="From simple charts to immersive experiences: we provide you with a wide range of custom data visualizations."
        iconType="datavis"
      />
      <GridItem
        title="Interactive Maps"
        label="Whether point map or choropleth – benefit from our experience in developing all types of web maps."
        iconType="maps"
      />
      <GridItem
        title="Custom Tools"
        label="Some datasets require individual solutions. We can implement your vision into anything from custom dashboards to 3D-wordclouds."
        iconType="tools"
      />
      <GridItem
        title="Data Processing"
        label="Move your data beyond spreadsheets by having us develop APIs, scrapers and data transformation solutions."
        iconType="dataprocessing"
      />
    </StyledFlex>
  );
};

export default ServiceGrid;
