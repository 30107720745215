import { useStaticQuery, graphql } from 'gatsby';

export default function useProjectCount() {
  const data = useStaticQuery(graphql`
    query ProjectCount {
      projects: allProjectsJson {
        totalCount
      }
      featuredProjects: allMdx(
        filter: {
          fields: { slug: { regex: "/projects/" } }
          frontmatter: { published: { eq: true } }
        }
      ) {
        totalCount
      }
    }
  `);

  return data.projects.totalCount + data.featuredProjects.totalCount;
}
