import React from 'react';
import { Flex, Box } from 'components/ReflexBox';
import { Link } from 'gatsby';

import Page from 'components/Page';
import Intro from 'components/Intro';
import ContentSection from 'components/ContentSection';
import CenterContent from 'components/CenterContent';
import SectionIntro from 'components/SectionIntro';
import ServiceGrid from 'components/ServiceGrid';
import ClientGrid from 'components/ClientGrid';
import Button from 'components/Button';
import ProjectTeaser from 'components/ProjectTeaser/Large';
import Contact from 'components/Contact';

import useFeaturedHomeProjects from 'hooks/useFeaturedHomeProjects';
import useProjectCount from 'hooks/useProjectCount';

const metaTags = {
  title: 'webkid',
  author: 'Webkid',
  twitterUsername: '@webk1d',
  description:
    'webkid is a Berlin based data visualization agency specialized in data driven applications and interactive maps',
  siteUrl: 'https://webkid.io',
  robots: 'index,follow',
};

const Home = () => {
  const featuredProjects = useFeaturedHomeProjects();
  const projectCount = useProjectCount();

  return (
    <Page metaTags={metaTags} showBanner>
      <Intro
        title={
          <span>
            Your data.
            <br />
            Perfectly presented.
          </span>
        }
      />

      <ContentSection centered big>
        <SectionIntro
          title="Our Services"
          text="webkid is a web development agency specialized in data-driven applications. We create innovative visualizations, customized tools and interactive maps that make your data easier to understand."
        />
        <ServiceGrid />
        <Flex justifyContent="center" mt={5}>
          <Button as={Link} to="services" icon="graph_bar">
            Learn More About Our Services
          </Button>
        </Flex>
      </ContentSection>

      <ContentSection bg="violetLighten5">
        <CenterContent>
          <SectionIntro title="Featured Projects" />
        </CenterContent>
        <CenterContent p={3}>
          <Flex flexWrap="wrap" mb={[3, 4, 6]} justifyContent="space-between">
            {featuredProjects.map((project) => (
              <Box width={[1, 1, '45%']} mb={[4, 4, 0]} key={project.title}>
                <ProjectTeaser
                  key={project.id}
                  kicker={project.client}
                  title={project.title}
                  text={project.description}
                  image={project.imageTeaser?.childImageSharp.gatsbyImageData}
                  slug={project.slug}
                />
              </Box>
            ))}
          </Flex>
          <Flex justifyContent="center">
            <Button as={Link} to="portfolio" icon="lightning">
              Show All {projectCount} Projects
            </Button>
          </Flex>
        </CenterContent>
      </ContentSection>

      <ContentSection centered>
        <SectionIntro
          title="Our Clients"
          text="We are collaborating with a wide range of customers. These include large news organizations, start-ups, NGOs and medium-sized companies."
        />
        <ClientGrid />
      </ContentSection>

      <ContentSection centered>
        <SectionIntro
          title="Contact Us"
          text="Are you interested in a collaboration? We are looking forward to hearing about your project. Get in touch!"
        />
        <Contact mb={4} />
      </ContentSection>
    </Page>
  );
};

export default Home;
