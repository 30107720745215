import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'components/ReflexBox';
import useSvg from 'hooks/useSvg';

const Card = styled(Flex)`
  background: ${(p) => p.theme.colors.dark.cardBackground};
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  height: 120px;
  position: relative;
`;

const ClientLogo = styled.img`
  width: ${(p) => p.width};
  max-height: ${(p) => p.height};
`;

const ClientCard = ({ client, width = '80%', height = '60%' }) => {
  const svgSrc = useSvg(client, { path: 'clients' });

  if (!svgSrc) {
    return null;
  }

  return (
    <Card>
      <ClientLogo width={width} height={height} src={svgSrc} />
    </Card>
  );
};

export default ClientCard;
